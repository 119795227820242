
import { useVModel } from "@vueuse/core";
import { defineComponent, reactive, ref } from "vue";
import { useUpload, UfUploadFileType } from "@/composables";

export default defineComponent({
  name: "Praise",
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const innerShow = useVModel(props, "show", emit);
    const files = ref<UfUploadFileType[]>([]);
    const state = reactive({
      serve_result: 5,
      speed_result: 5,
      professional_result: 5,
      praise_pic: "",
      praise_notes: ""
    });
    const submit = () => {
      state.praise_pic = files.value.map(file => file.res.id).join(",");
      emit("submit", state);
      innerShow.value = false;
    };
    const { upload } = useUpload();
    return {
      files,
      upload,
      innerShow,
      state,
      submit
    };
  }
});
