
import { useVModel } from "@vueuse/core";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "Exanube",
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const innerShow = useVModel(props, "show", emit);
    const state = reactive({
      state: "1",
      desc: ""
    });
    const submit = () => {
      emit("submit", state);
      innerShow.value = false;
    };
    return {
      innerShow,
      state,
      submit
    };
  }
});
