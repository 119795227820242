
import { eventsService } from "@/service";
import { defineComponent, toRefs, ref, reactive, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { Dialog, ImagePreview, Toast } from "vant";
import Exanube from "./components/Exanube.vue";
import Praise from "./components/Praise.vue";
import Approval from "./components/Approval.vue";
import { RouterName } from "@/router";

interface UserInfo {
  department_name: string;
  name: string;
  head_pic: string;
  id: string;
  _state: number;
}
interface EventLog {
  show_content: string;
  opt_time_name: string;
  opt_uid_array: UserInfo[];
}
type CloseInfo = {
  close_reason: string;
  close_time_name: string;
  opt_content: string;
  pic_ids_lists: Approval.FileRes[];
  reason_desc: string;
  approval_uids_do_array: UserInfo[];
  approval_uids_array: UserInfo[];
  reason_desc_arr: { event_code: string; id: string }[];
};

type PraiseInfo = {
  send_time_name: string;
  praise_notes: string;
  praise_pic_lists: Approval.FileRes[];
  professional_result: string;
  serve_result: string;
  speed_result: string;
};

type OptList = {
  button_key: string;
  button_name: string;
  is_common: string;
  desc: string;
};

type PraiseState = {
  serve_result: string;
  speed_result: string;
  professional_result: string;
  praise_pic: string;
  praise_notes: string;
};
type Operation = {
  code: string;
  create_time_name: string;
  icon: string;
  name: string;
  state: string;
  id: string;
  state_name: string;
  type: 2;
};
type Operation2 = {
  cause: string;
  fault_time_name: string;
  faulttype_name: string;
  id: string;
  orderid: string;
  repair_user_array: [];
  repairstate: string;
  repairstate_name: string;
  type: 1;
};
type HandleInfo = {
  accept_time_name: string;
  handler_uid_array: UserInfo[];
  operation_lists: (Operation | Operation2)[];
};
const getDefaultForm = () => ({
  id: "",
  event_state: "",
  event_state_name: "",
  event_type: "",
  event_type_name: "",
  event_code: "",
  close_state: "",
  is_evaluate: "",
  is_overtime_name: "",
  is_overtime: "",
  is_overtime_handler: "",
  event_log: [] as EventLog[],
  user_id_detail: [] as UserInfo[],
  create_time_time: "",
  close: {} as CloseInfo,
  praise: {} as PraiseInfo,
  opt_lists: [] as OptList[],
  handle: {} as HandleInfo,
  pool_type: "",
  tag_exception: {},
  patrol_route_detail: {},
  overtime_term_name: "",
  stores_name: "",
  delay_num: "",
  delay_time_name: "",
  delay_opt_content: "",
  delay_approval_uids_all: []
});
type EventsInfo = ReturnType<typeof getDefaultForm>;
export default defineComponent({
  name: "EventsDetail",
  components: {
    Exanube,
    Praise,
    Approval
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const eventsInfo = reactive<EventsInfo>(getDefaultForm());
    const tempInfo = ref<Approval.SubComponents | null>(null);
    const overlayShow = ref(false);
    const showLock = ref(false);
    const getEventDetail = async () => {
      if (!route.params.id) return;
      try {
        overlayShow.value = true;
        const { data } = await eventsService.eventDetail(route.params.id as string);
        (data.data.event_json as Approval.SubComponents).forEach(item => {
          item.id = uuidv4();
        });
        tempInfo.value = _.cloneDeep(data.data.event_json);
        tempInfo.value?.forEach((item: any) => {
          item._type = item.type;
          item.type = item.check_type;
          item.power = 1;
          if (item.photo_num) {
            item.num_max = item.photo_num;
          }
          if (item.place_arr) {
            item.place = item.place_arr.map((place: any) => place.true_place).join(",");
          }
        });

        Object.assign(eventsInfo, data.data);
        eventsInfo.opt_lists = eventsInfo.opt_lists.filter(item => {
          return ["1", "11", "12", "19"].includes(item.button_key);
        });
      } catch (error) {
        if (error.state === "014") {
          showLock.value = true;
        }
        Object.assign(eventsInfo, getDefaultForm());
      }
      overlayShow.value = false;
    };

    getEventDetail();

    watch(
      () => route.params.id,
      () => {
        if (route.name != RouterName.EVENTS_DETAIL) return;
        eventsInfo.id = "";
        console.log(route.name, "haha");
        getEventDetail();
      }
    );
    // 页面的审核人
    const approvalList = computed<UserInfo[]>(() => {
      if (eventsInfo.close.approval_uids_array.length > 0) {
        eventsInfo.close.approval_uids_array.forEach((item, index) => {
          if (index === 0) {
            item._state = 2;
            return;
          }
          item._state = 3;
        });
      }
      if (eventsInfo.close.approval_uids_do_array.length > 0) {
        eventsInfo.close.approval_uids_do_array.forEach(item => {
          item._state = 1;
        });
      }
      return [...eventsInfo.close.approval_uids_do_array, ...eventsInfo.close.approval_uids_array];
    });
    // 跳转到工单或者审批详情
    const toOperationDetail = (type: number, id: string) => {
      if (type === 1) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: `/pages/guaranteeDetil/main?id=${id}`
        });
        return;
      }
      router.push({
        name: RouterName.APPROVAL_DETAIL,
        params: {
          id
        }
      });
    };
    // 审核相关
    const exanubeState = reactive({
      show: false,
      submit(state: { state: string; desc: string }) {}
    });
    // 评价
    const praiseState = reactive({
      show: false,
      submit(state: PraiseState) {}
    });
    // 审核人弹窗
    const approvalState = reactive({
      show: false,
      show2: false
    });
    const buttonClick = async (key: string) => {
      try {
        switch (key) {
          case "1": {
            await Dialog.confirm({
              message: "确定要取消报事吗"
            });
            await eventsService.cancelEvent(eventsInfo.id);
            Toast.success("取消成功");
            break;
          }
          case "19": {
            await Dialog.confirm({
              message: "确定要重新报事吗"
            });
            router.push({
              name: RouterName.EVENTS_STRAT,
              params: {
                id: eventsInfo.event_type
              },
              query: {
                id: eventsInfo.id
              }
            });
            break;
          }
          case "11": {
            exanubeState.show = true;
            const state = await new Promise<{ state: string; desc: string }>(resolve => {
              exanubeState.submit = state => {
                resolve(state);
              };
            });
            await eventsService.exanubeEvent({
              id: eventsInfo.id,
              ...state
            });
            Toast.success("审核成功！");
            break;
          }
          case "12": {
            praiseState.show = true;
            const state = await new Promise<PraiseState>(resolve => {
              praiseState.submit = state => {
                resolve(state);
              };
            });
            await eventsService.addPraise({
              id: eventsInfo.id,
              ...state
            });
            Toast.success("评价成功！");
            break;
          }
          default:
            break;
        }
        eventsInfo.id = "";
        getEventDetail();
      } catch (error) {}
    };
    return {
      moment,
      tempInfo,
      ImagePreview,
      buttonClick,
      exanubeState,
      praiseState,
      approvalState,
      approvalList,
      overlayShow,
      toOperationDetail,
      showLock,
      ...toRefs(eventsInfo)
    };
  }
});
